import React, { useEffect } from "react";
import WalletPassContainer from "./WalletPassContainer";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

const WalletPass = (props) => {
  const { setShopifyDomain } = props;

  const { shopify_domain } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    let _domain = searchParams.get("domain") || shopify_domain;
    if (_domain) {
      const _shopify_domain = `${_domain.replaceAll(
        ".myshopify.com",
        ""
      )}.myshopify.com`;
      setShopifyDomain(_shopify_domain);
    }
  }, [shopify_domain, searchParams.get("domain")]);

  return (
    <div className="chargezen_wallet_container">
      <div className="chargezen_modal_container">
        <WalletPassContainer {...props} />
      </div>
    </div>
  );
};

export default WalletPass;
