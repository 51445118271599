import React, { useState } from "react";
import QRCode from "react-qr-code";
import DynamicStyle from "./DynamicStyle";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { Oval } from "react-loader-spinner";

const WalletPassContainer = (props) => {
  const {
    shopifyDomain,
    setShopifyDomain,
    customer,
    setCustomer,
    apiData,
    setApiData,
    chargel,
    authToken,
  } = props;
  const [formData, setFormData] = useState();
  const [regisError, setRegisError] = useState();

  const hangelChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };
  const [loading, setLoading] = useState(false);

  const registerCustomer = () => {
    if (formData.email && !loading) {
      setLoading(true);
      setRegisError(false);
      const _formData = formData;
      _formData.shopify_domain = shopifyDomain;
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}apple_pass_register_customer`,
        method: "post",
        data: _formData,
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log("ressss", response);
          let _customer = response?.data?.customer;
          if (_customer) {
            secureLocalStorage.setItem("loggedIn", JSON.stringify(_customer));
            setCustomer(_customer);
          } else {
            console.log("Something went wrong.");
            setRegisError("Something went wrong.");
          }
          setLoading(false);
        })
        .catch((error) => {
          setRegisError("Something went wrong.");
          setLoading(false);
        });
    }
  };

  return (
    <div className="logged_out_state_container">
      <DynamicStyle {...{ apiData }} />
      <div className="logo-top-left">
        <img src={apiData?.setting?.custom_logo} />
      </div>
      <div className="body-model-wrap">
        <div
          className={`logged_out_state_wrapper ${
            customer ? "logged_in_state_wrapper" : ""
          }`}
        >
          <div className="left_section_wrapper">
            {customer ? (
              <div className="content-max-width">
                <h3 className="welcome_back_message_wrapper">
                  Welcome back{" "}
                  <span className="customer_name_wrapper">
                    {customer?.first_name?.toUpperCase()}
                  </span>
                </h3>
                <h2 className="sub_header_main">
                  Loyalty and membership passes on Apple platforms
                </h2>
                <p className="description_text">
                  Discover how Apple Pay and Apple Wallet make it simple to
                  enroll, reward, and engage customers on their iPhone or Apple
                  Watch.
                </p>
                <div className="qr_code_container">
                  <QRCode value="testing" />
                </div>
              </div>
            ) : (
              <div className="content-max-width">
                <h2 className="sub_header_main">
                  {apiData?.setting?.title ||
                    "Loyalty and membership passes on Apple platforms"}
                </h2>
                <p className="description_text">
                  {apiData?.setting?.subtitle ||
                    "Discover how Apple Pay and Apple Wallet make it simple to enroll, reward, and engage customers on their iPhone or Apple Watch."}
                </p>
                <div className="get_pass_form_container">
                  <div className="pass_form_control">
                    <label>
                      <input
                        className="text_fields"
                        id="name"
                        type="text"
                        placeholder="Full name"
                        value={formData?.name}
                        onChange={(e) => {
                          hangelChange(e);
                        }}
                      />
                    </label>
                    <label>
                      <input
                        className="text_fields"
                        id="email"
                        type="text"
                        placeholder="Email"
                        value={formData?.email}
                        onChange={(e) => {
                          hangelChange(e);
                        }}
                      />
                    </label>
                  </div>
                  {loading ? (
                    <div className="loading_spinner_container">
                      <Oval
                        height={30}
                        width={30}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="get_pass_button"
                      onClick={() => {
                        registerCustomer();
                      }}
                    >
                      Get pass
                    </button>
                  )}
                  {regisError && (
                    <div className="something_went_wrong">{regisError}</div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="right_section_wrapper">
            <h3 className="redeem-text">REDEEM</h3>
            {/* <div className="mobile-case-upper">
              <div className="network-icon">
                <span>
                  <img src="https://myobvi.novel.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FGroup.b79879b6.png&w=96&q=75" />
                </span>
              </div>
              <div className="member-pass-container-modal-preview">
                <div className="mobile_wrapper">
                  <div className="Mobile_upper">
                    <div className="pass-logo">
                      <img src="/assets/images/logo.png" />
                      <div className="logo-name">Obvi</div>
                    </div>
                    <div className="passname">Silver</div>
                    <div className="Points-div-wrapper">
                      <div>
                        <span>Member</span>
                        <span>• • • • • • • • • •</span>
                      </div>
                      <div>
                        <span>Points</span>
                        <span>500</span>
                      </div>
                    </div>
                  </div>
                  <div className="mobile_lower">
                    <div className="qr-code-wrap">
                      <div className="code-containr">
                        <img src="/assets/images/qr_code.png" />
                        <span>Give 300 pts 🤝 Get 300 pts</span>
                      </div>
                    </div>
                    <div className="botom-footer-image">
                      <img src="/assets/images/bottom-iphone-footer.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="mobile_simulator_wrapper">
              <div class="mobile_nav">
                <img src="/assets/images/mobile_nav.jpg" />
              </div>
              <div className="claim-reward hiddn-md">
                <span>CLAIM</span>
                <span>500 POINTS</span>
              </div>
              <div className="mobile_simulator_screen_container">
                <div className="screen_main_container">
                  <div className="mobile_header_wrapper">
                    <div className="left_section flex_wrapper">
                      <div className="logo_wrapper">
                        <img
                          src={
                            apiData?.setting?.custom_logo ||
                            "https://cdn.shopify.com/shopifycloud/web/assets/v1/c30f6bec42c375fd.png"
                          }
                          alt="custom_logo"
                        />
                      </div>
                      <div className="logo_title regular_text_color">
                        {apiData?.setting?.pass_header}
                      </div>
                    </div>
                    <div className="right_section">
                      <div className="balance_wrapper">
                        <div className="balance_title secondary_text_color">
                          BALANCE
                        </div>
                        <div className="balanace_container regular_text_color">
                          $13.56
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mobile_banner_wrapper">
                    <div className="banner_container">
                      <img
                        src={
                          apiData?.setting?.custom_banner ||
                          "https://cdn.shopify.com/shopifycloud/web/assets/v1/7db28644100d0c6d.png"
                        }
                        alt=" "
                      />
                    </div>
                  </div>
                  <div className="mobile_scanner_wrapper">
                    <div className="qr_code_wrapper">
                      <img
                        src="https://cdn.shopify.com/shopifycloud/web/assets/v1/d622771b83fc2479.png"
                        alt=" "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="box-footer-mobile">
              <button className="border-transparent">
                <img src="https://myobvi.novel.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FAppleWalletIcon.d0b29483.png&w=32&q=75" />
                <span>Apple</span>
              </button>
              <button className="border-transparent">
                <img src="https://myobvi.novel.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FGoogleWalletIcon.d1134cd4.png&w=16&q=75" />
                <span>Google</span>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletPassContainer;
