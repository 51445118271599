import logo from "./logo.svg";
import "./App.css";
import WalletPass from "./components/WalletPass";
import "./components/style/main-style.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

function App() {
  const [shopifyDomain, setShopifyDomain] = useState();
  const [customer, setCustomer] = useState();
  const [apiData, setApiData] = useState();
  const [authToken, setAuthToken] = useState();

  const fetchAuthToken = () => {
    if (process.env.NODE_ENV === "production") {
      fetch(
        `${process.env.REACT_APP_BACKEND_URL}cloudflare/fetch_chargezen_token`,
        {
          method: "GET",
          headers: {
            Authorization: shopifyDomain,
          },
        }
      )
        .then((r) => {
          return r.json();
        })
        .then((data) => {
          setAuthToken(data?.chargezen_token);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setAuthToken("fnvRrS7M_iT5cmCVoEhFmw");
    }
  };

  function getApplepayPassesSetting() {
    let url = `${process.env.REACT_APP_BACKEND_URL}cloudflare/get_applepay_passes_setting?shopify_domain=${shopifyDomain}`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: authToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let _apiData = data;
        setApiData(_apiData);
      });
  }

  function chargel(key, args = null) {
    if (apiData?.translations) {
      let translation = apiData?.translations?.[key];
      if (args) {
        Object.keys(args)?.map((k) => {
          translation = translation?.replace(`{{${k}}}`, args?.[k]);
        });
      }
      return translation;
    }
  }

  useEffect(() => {
    console.log("shopifyDomainshopifyDomain", shopifyDomain);
    if (shopifyDomain) {
      fetchAuthToken();
    }
  }, [shopifyDomain]);

  useEffect(() => {
    if (authToken) {
      getApplepayPassesSetting();
    }
  }, [authToken]);

  console.log("shopifyDomain", shopifyDomain);

  //check if user is already logged in
  useEffect(() => {
    let _loggedin = secureLocalStorage.getItem("loggedIn");
    if (_loggedin) {
      _loggedin = JSON.parse(_loggedin);
      setCustomer(_loggedin);
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <WalletPass
                {...{
                  authToken,
                  shopifyDomain,
                  setShopifyDomain,
                  customer,
                  setCustomer,
                  apiData,
                  setApiData,
                  chargel,
                }}
              />
            }
          />
          <Route
            path="/:shopify_domain"
            element={
              <WalletPass
                {...{
                  authToken,
                  shopifyDomain,
                  setShopifyDomain,
                  customer,
                  setCustomer,
                  apiData,
                  setApiData,
                  chargel,
                }}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
