import React, { useEffect, useState } from "react";

const DynamicStyle = (props) => {
  const { apiData } = props;
  const [style, setStyle] = useState();

  useEffect(() => {
    const _style = `
        .screen_main_container{
            background-color: ${apiData?.setting?.pass_color} !important;
        }
        .regular_text_color {
            color: ${apiData?.setting?.regular_text_color} !important;
        }
        .secondary_text_color {
            color: ${apiData?.setting?.secondary_text_color} !important;
        }
    `;
    setStyle(_style);
  }, [apiData]);

  return <style dangerouslySetInnerHTML={{ __html: style }} />;
};

export default DynamicStyle;
